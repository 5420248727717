import axios from "axios"
import moment from "moment"

export default ({
    data: () => ({
        //
    }),
    metodos: {
        async Departamentos() {            
            return await axios.get("api/Departamentos/ListarTodos");
        },
        async Departamento(id) {
            return await axios.get("api/Departamentos/Listar/" + id);
        },
        async DepartamentoCodMH(cod_MH) {
            return await axios.get("api/Departamentos/ListarCodMH/" + cod_MH);
        },
        async Municipios(codigo) {
            return await axios.get("api/Municipios/Listar/" + codigo);
        },
        async Paises() {
            return await axios.get("api/Paises/Listar");
        },
        async Identificador() {
            return await axios.get("api/Identificadores/Listar");
        },
        async TipoDocFiscal() {
            return await axios.get("api/TipoDocFiscales/Listar");
        },
        async TipoExportacion() {
            return await axios.get("api/TipoExportaciones/Listar");
        },
        async Actividad_Economica() {
            return await axios.get("api/ActividadEconomicas/Listar");
        },
        async Empresa(id_usuario, cod_emp) {
            if (cod_emp) {
                return await axios.get("api/UsuariosXEmpresas/ListarXcodEmp/" + cod_emp + "/" + id_usuario);
            } else {
                return null;
            }
        },
        async Sucursales(id_empresa) {
            if (id_empresa) {
                return await axios.get("api/Sucursales/Listar/" + id_empresa)
            }
        },
        //OTROS
        formatoFecha(fecha) {
            return moment(fecha).format("yyyy-MM-DD");
        },
        formatoFechaHora(fecha_hora) {
            return moment(fecha_hora).format("yyyy-MM-DD hh:mm")
        }
    }
})