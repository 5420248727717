<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Roles</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Buscar"
            outlined
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoRol()"> Nuevo </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-text-field
            v-model="nombre"
            class="mt-3"
            label="Nombre"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="crearRol()" color="primary">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()"> Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="7000"
      top
      right
      outlined
      color="primary"
    >
      <v-icon color="yellow">fa-solid fa-circle-info</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    search: "",
    headers: [{ text: "Nombre", value: "nombre" }],
    items: [],
    dialog: false,
    nombre: "",
    snackbar: false,
    snackbar_text: "",
  }),
  created() {
    this.listarRoles();
  },
  methods: {
    listarRoles() {
      axios.get("api/Roles/Listar").then((response) => {
        this.items = response.data;
      });
    },
    nuevoRol() {
      this.dialog = true;
    },
    crearRol() {
      axios
        .post("api/Roles/Crear", {
          nombre: this.nombre,
        })
        .then(() => {
          this.snackbar_text = "Registro creado.";
          this.snackbar = true;

          this.listarRoles();
          this.cerrarDialog();
        })
        .catch(() => {
          this.snackbar_text = "Error de crear.";
          this.snackbar = true;
        });
    },
    cerrarDialog() {
      this.dialog = false;
      this.nombre = "";
    },
  },
};
</script>