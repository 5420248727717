<template>
  <v-container>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Países</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoPaises()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarPais(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="nombre"
            label="Nombre"
            class="mt-3"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="flag_new_edit == 1" color="primary" @click="crearPais()">Guardar</v-btn>
          <v-btn v-if="flag_new_edit == 2" color="primary" @click="actualizarPais()">Actualizar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="7000"
      top
      right
      outlined
      color="primary"
    >
      <v-icon color="yellow">fa-solid fa-circle-info</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    headers: [
      { text: "Nombre", value: "nombre" },
      {
        text: "Editar",
        value: "action_edit",
        sorteable: false,
        align: "center",
      },
    ],
    items: [],
    snackbar: false,
    snackbar_text: "",
    flag_new_edit: 1,
    dialog: false,
    id_pais: 0,
    nombre: "",
  }),
  created() {
    this.listarPaises();
  },
  methods: {
    listarPaises() {
      axios.get("api/Paises/Listar").then((response) => {
        this.items = response.data;
      });
    },
    nuevoPaises() {
      this.flag_new_edit = 1;
      this.dialog = true;
    },
    crearPais() {
      axios
        .post("api/paises/Crear", {
          nombre: this.nombre,
        })
        .then(() => {
          this.snackbar_text = "Registro creado con exito";
          this.snackbar = true;

          this.cerrarDialog();
          this.listarPaises();
        })
        .catch(() => {
          this.snackbar_text = "No se pudo registrar.";
          this.snackbar = true;
        });
    },
    actualizarPais() {
      axios
        .put("api/paises/Actualizar", {
          id_pais: this.id_pais,
          nombre: this.nombre,
        })
        .then(() => {
          this.snackbar_text = "Registro actualizado con exito";
          this.snackbar = true;

          this.cerrarDialog();
          this.listarPaises();
        })
        .catch(() => {
          this.snackbar_text = "No se pudo actualizar.";
          this.snackbar = true;
        });
    },
    editarPais(item) {
      this.id_pais = item.id_pais;
      this.nombre = item.nombre;
      this.flag_new_edit = 2;

      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.nombre = "";
    },
  },
};
</script>