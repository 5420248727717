<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Productos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoProducto()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarProducto(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>

      <template v-slot:[`item.activo`]="{ item }">
        <span>{{ conversion(item.activo) }}</span>
      </template>
      <template v-slot:[`item.exento`]="{ item }">
        <span>{{ conversion(item.exento) }}</span>
      </template>
      <template v-slot:[`item.no_sujeto`]="{ item }">
        <span>{{ conversion(item.no_sujeto) }}</span>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>Productos</v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="codigo_producto"
                label="Código"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="codigo_barra"
                label="Código Barra"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox v-model="activo" label="ACTIVO" dense></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox v-model="exento" label="EXENTO" dense></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                v-model="no_sujeto"
                label="NO SUJETO"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="precio"
                label="Precio"
                prefix="$"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearProducto()"
            v-if="flag_new_edit == 1"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarProducto()"
            v-if="flag_new_edit == 2"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
  
<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,
    snackbar: false,
    icon_snackbar: "fa-solid fa-circle-info",
    snackbar_text: "Mensaje",
    headers: [
      { text: "Código", value: "cod_producto" },
      { text: "Código Barra", value: "cod_barra" },
      { text: "Nombre", value: "nom_producto" },
      { text: "Activo", value: "activo" },
      { text: "Exento", value: "exento" },
      { text: "No Sujeto", value: "no_sujeto" },
      { text: "Precio", value: "precio" },
      { text: "Editar", value: "action_edit", sortable: false },
    ],
    id_producto: 0,
    id_empresa: 0,
    codigo_barra: "",
    codigo_producto: "",
    nombre: "",
    activo: false,
    exento: false,
    no_sujeto: false,
    precio: "",
    items: [],
    flag_new_edit: 1,
  }),
  created() {
    this.listarProductos();
  },
  methods: {
    conversion(item) {
      if (item) {
        return "Activo";
      } else {
        return "Inactivo";
      }
    },
    listarProductos() {
      axios
        .get("api/Productos/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items = response.data;
        });
    },
    nuevoProducto() {
      this.flag_new_edit = 1;
      this.dialog = true;
    },
    crearProducto() {
      axios
        .post("api/Productos/Crear", {
          id_empresa: this.$store.state.emp.id_empresa,
          activo: this.activo,
          cod_barra: this.codigo_barra,
          cod_producto: this.codigo_producto,
          exento: this.exento,
          nom_producto: this.nombre,
          no_sujeto: this.no_sujeto, 
          precio: this.precio,   
        })
        .then(() => {
          this.snackbar_text = "Se ha creado la sucursal.";
          this.snackbar = true;

          this.cerrarDialog();
          this.listarProductos();
        })
        .catch(() => {
          this.snackbar_text = "No se ha creado la sucursal.";
          this.snackbar = true;
        });
    },
    editarProducto(item) {
      //console.log(item);
      this.id_producto = item.id_producto;
      this.id_empresa = item.id_empresa;
      this.activo = item.activo;
      this.codigo_barra = item.cod_barra;
      this.codigo_producto = item.cod_producto;
      this.exento = item.exento;
      this.no_sujeto = item.no_sujeto;
      this.nombre = item.nom_producto;
      this.precio = item.precio;

      this.flag_new_edit = 2;
      this.dialog = true;
    },
    actualizarProducto() {
      axios
        .put("api/Productos/Actualizar", {
          id_producto: this.id_producto,
          id_empresa: this.id_empresa,
          activo: this.activo,
          cod_barra: this.codigo_barra,
          cod_producto: this.codigo_producto,
          exento: this.exento,
          nom_producto: this.nombre,
          no_sujeto: this.no_sujeto,
          precio: this.precio,
        })
        .then(() => {
          this.snackbar_text = "Se ha actualizado la sucursal.";
          this.snackbar = true;

          this.cerrarDialog();
          this.listarProductos();
        })
        .catch(() => {
          this.snackbar_text = "No se ha actualizado la sucursal.";
          this.snackbar = true;
        });
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    limpiar() {
      this.id_producto = 0;
      this.id_empresa = 0;
      this.codigo_barra = "";
      this.activo = "";
      this.codigo_producto = "";
      this.nombre = "";
      this.exento = false;
      this.no_sujeto = false;
      this.precio = "";
    },
  },
};
</script>