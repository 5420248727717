<template>
  <v-container fluid>
    <v-data-table :items="items_proveedores" :headers="headers_proveedores">
      <template v-slot:top>
        <v-btn color="primary" @click="generarExcel()">Generar</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
var XLSX = require("xlsx");

export default {
  data: () => ({
    items_proveedores: [],
    headers_proveedores: [
      { text: "Código", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Teléfono", value: "telefono" },
      { text: "Correo", value: "correo_1" },
    ],
    items_reporte: [],
  }),
  created() {
    this.listar();
  },
  methods: {
    listar() {
      axios
        .get("api/Proveedores/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_proveedores = response.data;
        });
    },
    generarExcel() {
      this.items_proveedores.forEach((element) => {
        this.items_reporte.push({
          Código: element.codigo,
          Nombre: element.nombre,
          Departamento: element.nom_departamento,
          Municipio: element.nom_municipio,
          Tamaño: element.tamaño,
          Registro: element.registro,
          "Correo 1": element.correo_1,
          "Correo 2": element.correo_2,
          Teléfono: element.telefono,
          Documento: element.documento_identificador,
          "Con retención": this.convertirBooleano(element.con_retencion),
          Activo: this.convertirBooleano(element.cliente_activo),
          Exento: this.convertirBooleano(element.exento),
          "Con Percepcion": this.convertirBooleano(element.con_percepcion),
          Área: element.area,
          Dirección: element.dirección,
          Exterior: this.convertirBooleano(element.exterior),
          Giro: element.giro,
        });
      });
      let data = XLSX.utils.json_to_sheet(this.items_reporte);
      const workbook = XLSX.utils.book_new();
      const filename = "Proveedores";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    convertirBooleano(item) {
      if (item) {
        return "SI";
      } else {
        return "NO";
      }
    },
  },
};
</script>