<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Usuarios</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                label="Buscar"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="nuevoUsuario()"> Nuevo </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:[`item.editar`]="{ item }">
            <v-icon class="mr-2" @click="editarUsuario(item)">
              fa-solid fa-pen-to-square
            </v-icon>
          </template>

          <template v-slot:[`item.action_emp`]="{ item }">
            <v-icon class="mr-2" @click="listarEmpresasXusuario(item)">
              fa-solid fa-handshake
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_usuario" width="500" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="correo"
                label="Correo"
                outlined
                dense
                :rules="email_rules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="id_rol"
                :items="items_roles"
                item-text="nombre"
                item-value="id_rol"
                label="Rol"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="contraseña"
                label="Contraseña"
                outlined
                dense
                hide-details
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="crearUsuario()" v-if="code == 0"
            >Guardar</v-btn
          >
          <v-btn color="primary" @click="actualizarUsuario()" v-if="code == 1"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogNuevoUsuario()"> Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_empresas" width="600">
      <v-card>
        <v-card-text>
          <v-data-table
            v-model="select_emp"
            :headers="headers_emp"
            :items="items_emp"
            show-select
            item-key="id_empresa"
            @item-selected="estatuSeleccionado"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Empresas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="aplicarCambios()"
            >Aplicar cambios</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cancelarEmp()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="7000"
      top
      right
      outlined
      color="primary"
    >
      <v-icon color="yellow">fa-solid fa-circle-info</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Nombre", value: "nombre", sortable: false },
      { text: "Correo", value: "correo", sortable: false },
      { text: "Editar", value: "editar", sortable: false, align: "center" },
      {
        text: "Empresas",
        value: "action_emp",
        sortable: false,
        align: "center",
      },
    ],
    items: [],
    code: 0,
    snackbar: false,
    snackbar_text: "Texto",
    email_rules: [
      (v) => !!v || "Correo es requerido",
      (v) => /.+@.+\..+/.test(v) || "Correo no es valido",
    ],
    //USUARIO
    dialog_usuario: false,
    id_usuario: 0,
    nombre: "",
    correo: "",
    contraseña: "",
    act_contraseña: "",
    //ROL
    id_rol: 0,
    items_roles: [],
    //EMPRESAS
    dialog_empresas: false,
    headers_emp: [{ text: "Nombre", value: "nombre_empresa" }],
    items_emp: [],
    select_emp: [],
    unselect_emp: [],
    loading: false,
  }),
  created() {
    this.listarUsuariosPorEmpresa();
    this.listarRoles();
    this.listarEmpresas();
    //console.log(this.$store.state.usuario);
  },
  methods: {
    listarUsuariosPorEmpresa() {
      this.loading = true;
      axios
        .get(
          "api/UsuariosXEmpresas/ListarXEmpresa/" +
            this.$store.state.emp.id_empresa
        )
        .then((response) => {
          //console.log(response.data);
          this.items = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    listarRoles() {
      axios.get("api/Roles/Listar").then((response) => {
        this.items_roles = response.data;
      });
    },
    listarEmpresas() {
      this.loading = true;
      if (this.$store.state.usuario.rol == "IT") {
        axios
          .get("api/DetallesEmpresas/Listar")
          .then((response) => {
            this.items_emp = response.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        axios
          .get("api/DetallesEmpresas/BusquedaEmpresa")
          .then((response) => {
            this.items_emp = response.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    listarEmpresasXusuario(item) {
      this.id_usuario = item.id_usuario;
      axios
        .get("api/UsuariosXEmpresas/ListarXid/" + item.id_usuario)
        .then((response) => {
          //console.log(response.data);
          this.select_emp = response.data;

          for (var i = 0; i < this.items_emp.length; i++) {
            for (var j = 0; j < response.data.length; j++) {
              if (
                this.items_emp[i].cod_empresa == response.data[j].cod_empresa
              ) {
                this.items_emp[i] = response.data[j];
              }
            }
          }
          this.dialog_empresas = true;
        });
    },
    nuevoUsuario() {
      this.dialog_usuario = true;
      this.code = 0;
    },
    crearUsuario() {
      axios
        .post("api/Usuarios/Crear", {
          nombre: this.nombre,
          id_rol: this.id_rol,
          correo: this.correo,
          password: this.contraseña,
        })
        .then(() => {
          this.snackbar_text = "Usuario creado";
          this.snackbar = true;
          this.dialog_usuario = false;
          this.limpiarVariables();
          this.listarUsuariosPorEmpresa();
        })
        .catch(() => {
          this.snackbar_text = "Sucedio algún error";
          this.snackbar = true;
        });
    },
    editarUsuario(item) {
      this.code = 1;
      this.id_usuario = item.id_usuario;
      this.id_rol = item.id_rol;
      this.nombre = item.nombre;
      this.correo = item.correo;
      this.contraseña = item.password_hash;
      this.act_contraseña = item.password_hash;

      this.dialog_usuario = true;
    },
    actualizarUsuario() {
      var flag = false;

      if (this.contraseña != this.act_contraseña) {
        flag = true;
      }

      axios
        .put("api/Usuarios/Actualizar", {
          id_usuario: this.id_usuario,
          id_rol: this.id_rol,
          nombre: this.nombre,
          correo: this.correo,
          password: this.contraseña,
          act_password: flag,
        })
        .then(() => {
          this.snackbar_text = "Usuario actualizado";
          this.snackbar = true;
          this.dialog_usuario = false;
          this.limpiarVariables();
          this.listarUsuariosPorEmpresa();
        })
        .catch(() => {
          this.snackbar_text = "Sucedio algún error";
          this.snackbar = true;
        });
    },
    cerrarDialogNuevoUsuario() {
      this.dialog_usuario = false;
    },
    limpiarVariables() {
      this.id_usuario = 0;
      this.id_rol = 0;
      this.nombre = "";
      this.correo = "";
      this.contraseña = "";
      this.act_contraseña = "";
    },
    //EMPRESAS//////////////////////////////////////////////////////////
    aplicarCambios() {
      Promise.all([this.guardarEmp(), this.removerEmp()]).then(() => {
        this.snackbar_text = "Cambios aplicados";
        this.snackbar = true;
        this.cancelarEmp();
      });
    },
    guardarEmp() {
      //console.log(this.select_emp);
      this.select_emp.forEach((element) => {
        if (!element.id_usuario) {
          axios.post("api/UsuariosXEmpresas/Crear", {
            id_usuario: this.id_usuario,
            id_empresa: element.id_empresa,
          });
        }
      });
    },
    removerEmp() {
      //console.log(this.unselect_emp);
      this.unselect_emp.forEach((element) => {
        if (element.id_usuario) {
          axios.delete(
            "api/UsuariosXEmpresas/Eliminar/" + element.id_usuarioXempresas
          );
        }
      });
    },
    estatuSeleccionado(data) {
      if (data.value) {
        //VERIFICAR EN unselect_emp Y REMOVER
        this.unselect_emp.splice(
          this.unselect_emp.findIndex((e) => e.cod_empresa == data.cod_empresa),
          1
        );
      } else {
        //AGREGAR EN unselect_emp
        this.unselect_emp.push(data.item);
      }
    },
    cancelarEmp() {
      this.select_emp = [];
      this.unselect_emp = [];
      this.dialog_empresas = false;
    },
  },
};
</script>