import { render, staticRenderFns } from "./Pagos.vue?vue&type=template&id=9a9b57f6&"
import script from "./Pagos.vue?vue&type=script&lang=js&"
export * from "./Pagos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports