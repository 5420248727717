<template>
  <v-container>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Sucursales</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevaSucursal()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarSucursal(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="codigo"
                label="Código"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="cod_mh"
                label="Código MH"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearSucursal()"
            v-if="flag_new_edit == 1"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarSucursal()"
            v-if="flag_new_edit == 2"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,
    snackbar: false,
    icon_snackbar: "fa-solid fa-circle-info",
    snackbar_text: "Mensaje",
    headers: [
      { text: "Código", value: "cod_sucursal" },
      { text: "Código MH", value: "cod_mh" },
      { text: "Nombre", value: "nombre" },
      { text: "Editar", value: "action_edit", sortable: false },
    ],
    items: [],
    id_sucursal: 0,
    id_empresa: 0,
    codigo: "",
    nombre: "",
    cod_mh: "",
    flag_new_edit: 1,
  }),
  created() {
    this.listarSucursales();
  },
  methods: {
    listarSucursales() {
      axios
        .get("api/Sucursales/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items = response.data;
        });
    },
    nuevaSucursal() {
      this.flag_new_edit = 1;
      this.dialog = true;
    },
    crearSucursal() {
      axios
        .post("api/Sucursales/Crear", {
          id_empresa: this.$store.state.emp.id_empresa,
          cod_sucursal: this.codigo,
          cod_mh: this.cod_mh,
          nombre: this.nombre,
        })
        .then(() => {
          this.snackbar_text = "Se ha creado la sucursal.";
          this.snackbar = true;

          this.cerrarDialog();
          this.listarSucursales();
        })
        .catch(() => {
          this.snackbar_text = "No se ha creado la sucursal.";
          this.snackbar = true;
        });
    },
    editarSucursal(item) {
      this.id_sucursal = item.id_sucursal;
      this.id_empresa = item.id_empresa;
      this.codigo = item.cod_sucursal;
      this.cod_mh = item.cod_mh;
      this.nombre = item.nombre;

      this.flag_new_edit = 2;
      this.dialog = true;
    },
    actualizarSucursal() {
      axios
        .put("api/Sucursales/Actualizar", {
          id_sucursal: this.id_sucursal,
          id_empresa: this.id_empresa,
          cod_sucursal: this.codigo,
          cod_mh: this.cod_mh,
          nombre: this.nombre,
        })
        .then(() => {
          this.snackbar_text = "Se ha actualizado la sucursal.";
          this.snackbar = true;

          this.cerrarDialog();
          this.listarSucursales();
        })
        .catch(() => {
          this.snackbar_text = "No se ha actualizado la sucursal.";
          this.snackbar = true;
        });
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    limpiar() {
      this.id_sucursal = 0;
      this.id_empresa = 0;
      this.codigo = "";
      this.cod_mh = "";
      this.nombre = "";
    },
  },
};
</script>