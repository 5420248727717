<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Resumen ventas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Inicio"
                outlined
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Fin"
                outlined
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action_json`]="{ item }">
        <v-icon @click="descargarJson(item)">far fa-file-code</v-icon>
      </template>
      <template v-slot:[`item.action_pdf`]="{ item }">
        <v-icon @click="descargarJson(item)">far fa-file-pdf</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Tipo doc.", value: "tipo_doc" },
      { text: "Documento", value: "documento" },
      { text: "Cod. generación", value: "cod_gen" },
      { text: "Sello recepción", value: "sello_recpecion" },
      { text: "Cliente", value: "cliente" },
      { text: "Total", value: "total" },
      { text: "JSON", value: "action_json" },
      { text: "PDF", value: "action_pdf" },
    ],
    items: [
      {
        fecha: "14/02/2023",
        tipo_doc: "CCF",
        documento: "0000001",
        cod_gen: "0000001",
        sello_recpecion: "00000001",
        cliente: "CLIENTE DE PRUEBA",
        total: "$0.01",
      },
    ],
  }),
  created() {},
  methods: {
    listarResumenes() {
      axios.get().then((response) => {});
    },
  },
};
</script>