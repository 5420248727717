<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-img
          :src="require('@/assets/banner-wompi.jpg')"
          max-width="200"
          max-height="200"
        ></v-img>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" block @click="autenticar()">Autenticar</v-btn>
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="token"
          label="Token"
          outlined
          hide-details
          dense
          auto-grow
          row-height="1"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" block @click="verRegiones()">Ver regiones</v-btn>
      </v-col>
      <v-col cols="6">
        <v-card outlined>
          <v-list dense style="max-height: 300px" class="overflow-y-auto pa-0">
            <v-subheader>REGIONES</v-subheader>
            <v-list-item v-for="(item, i) in regiones" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{ item.id }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.nombre }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" block @click="realizarTransaccion()"
          >Realizar Transaccion</v-btn
        >
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="transaccion"
          label="Transacción"
          outlined
          hide-details
          dense
          auto-grow
          row-height="1"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    token: "",
    regiones: [],
    transaccion: "",
  }),
  methods: {
    autenticar() {
      axios.get("api/WompiPagos/Autenticacion").then((response) => {
        this.token = "Bearer " + response.data.access_token;
      });
    },
    verRegiones() {
      axios
        .post("api/WompiPagos/Regiones", {
          access_token: this.token,
        })
        .then((response) => {
          response.data.forEach((element) => {
            if (element.id == "SV") {
              element.territorios.forEach((territorios) => {
                this.regiones.push({
                  id: territorios.id,
                  nombre: territorios.nombre,
                });
              });
            }
          });
        });
    },
    realizarTransaccion() {
      axios
        .post("api/WompiPagos/Transaccion", {
          access_token: this.token,
          tarjetaCreditoDebido: {
            numeroTarjeta: "4574952806392395",
            cvv: "111",
            mesVencimiento: 8,
            anioVencimiento: 2027,
          },
          monto: 0.01,
          urlRedirect: "https://factura.devco.sv",
          nombre: "John",
          apellido: "Linares",
          email: "johnlinares77@gmail.com",
          ciudad: "San Salvador",
          direccion: "Col. Las Palmas Parcela #16 Casa #14",
          idPais: "SV",
          idRegion: "SV-SS",
          codigoPostal: "1101",
          telefono: "75809621",
        })
        .then((response) => {
          console.log(response.data);
          this.transaccion = response.data.urlCompletarPago3Ds;
        })
        .catch((error) => {
          console.log(error.response.data.mensajes);
        });
    },
  },
};
</script>