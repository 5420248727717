<template>
  <v-container>
    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Vendedores</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Buscar"
            outlined
            dense
            hide-details=""
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoVendedor()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarVendedor(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="cod_vendedor"
                label="Código"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nom_vendedor"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearVendedor()"
            v-if="flag_new_edit == 1"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarVendedor()"
            v-if="flag_new_edit == 2"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
  
<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    snackbar: false,
    icon_snackbar: "fa-solid fa-circle-info",
    snackbar_text: "Mensaje",
    headers: [
      { text: "Código", value: "cod_vendedor" },
      { text: "Nombre", value: "nom_vendedor" },
      { text: "Editar", value: "action_edit", sortable: false },
    ],
    items: [],
    cod_vendedor: "",
    cod_vendedor_original: "",
    id_empresa: 0,
    search: "",
    codigo: "",
    nom_vendedor: "",
    flag_new_edit: 1,
  }),
  created() {
    this.listarVendedores();
  },
  methods: {
    listarVendedores() {
      axios
        .get("api/Vendedores/Listar/" + this.$store.state.usuario.id_empresa)
        .then((response) => {
          this.items = response.data;
        });
    },
    nuevoVendedor() {
      this.flag_new_edit = 1;
      this.dialog = true;
    },

    async validarVendedor(modo) {
      let xcodigo = "";
      let validar_cod_vendedor = false;
      let mensaje_error = "";

      if (modo == 1) {
        xcodigo = this.cod_vendedor;
        validar_cod_vendedor = true;
      } else if (modo == 2) {
        if (this.cod_vendedor != this.cod_vendedor_original) {
          validar_cod_vendedor = true;
        }
      }

      if (validar_cod_vendedor == true) {
        let test = await axios.get(
          "api/vendedores/ObtenerVendedorPorCodigo/" + this.cod_vendedor
        );
        if (test.data != "") {
          mensaje_error = "Código de Vendedor ya existe. ";
        }
      }

      if (this.cod_vendedor == "") {
        mensaje_error += "Debe ingresar código de Vendedor. ";
      }

      if (this.nom_vendedor == "") {
        mensaje_error += "Debe ingresar nombre de Vendedor. ";
      }

      if (mensaje_error != "") {
        this.snackbar_text = mensaje_error;
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },

    async crearVendedor() {
      let es_valido = await this.validarVendedor(1);
      if (es_valido == true) {
        axios
          .post("api/Vendedores/Crear", {
            id_empresa: this.$store.state.usuario.id_empresa,
            cod_vendedor: this.cod_vendedor,
            nom_vendedor: this.nom_vendedor,
          })
          .then(() => {
            this.snackbar_text = "Se ha creado el vendedor.";
            this.snackbar = true;

            this.cerrarDialog();
            this.listarVendedores();
          })
          .catch(() => {
            this.snackbar_text = "No se ha creado el vendedor.";
            this.snackbar = true;
          });
      }
    },
    editarVendedor(item) {
      this.id_vendedor = item.id_vendedor;
      this.cod_vendedor = item.cod_vendedor;
      this.nom_vendedor = item.nom_vendedor;

      this.flag_new_edit = 2;
      this.dialog = true;
    },

    async actualizarVendedor() {
      let es_valido = await this.validarVendedor(2);
      if (es_valido == true) {
        axios
          .put("api/Vendedores/Actualizar", {
            id_vendedor: this.id_vendedor,
            id_empresa: this.$store.state.usuario.id_empresa,
            cod_vendedor: this.cod_vendedor,
            nom_vendedor: this.nom_vendedor,
          })
          .then(() => {
            this.snackbar_text = "Se ha actualizado el vendedor.";
            this.snackbar = true;

            this.cerrarDialog();
            this.listarVendedores();
          })
          .catch(() => {
            this.snackbar_text = "No se ha actualizado el vendedor.";
            this.snackbar = true;
          });
      }
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    limpiar() {
      this.id_vendedor = 0;
      this.id_empresa = 0;
      this.cod_vendedor = "";
      this.nom_vendedor = "";
    },
  },
};
</script>