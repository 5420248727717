<template>
  <v-container fluid>
    <v-dialog v-model="estado_dialog" width="1000" persistent>
      <v-card>
        <v-card-title>{{ estadoProveedor }}</v-card-title>
        <!-- CODIGO, NOMBRE ... -->
        <v-card-text class="pt-5 pb-0">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field v-model="codigo" label="Código" @input="upperLetter" outlined dense
                hide-details></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field v-model="nombre" label="Nombre" @input="upperLetter" outlined dense
                hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete v-model="id_departamento" :items="items_departamento" item-text="nombre"
                item-value="id_departamento" label="Departamento" outlined dense hide-details
                @input="listarMunicipios(id_departamento)"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete v-model="id_municipio" :items="items_municipio" item-text="nombre"
                item-value="id_municipio" label="Municipio" outlined dense hide-details></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field v-model="direccion" label="Dirección" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete v-model="id_actividad_economica" :items="items_actividad_economica" item-text="nombre"
                item-value="id_actividad_economica" label="Actividad Economica" outlined dense
                hide-details></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete v-model="tamaño" :items="items_tamaño" label="Tamaño" outlined dense
                hide-details></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field v-model="correo_1" label="Correo" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field v-model="correo_2" label="Correo" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field v-model="telefono" label="Teléfono" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete v-model="id_identificador" :items="items_identificador" item-text="nombre"
                item-value="id_identificador" label="Identificador" outlined dense hide-details></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field v-model="documento_identificador" label="Documento" outlined dense
                hide-details></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="pa-1">
              <v-text-field v-model="registro" label="Registro" outlined dense hide-details
                :error="errorDocumentoIdentificador"></v-text-field>
            </v-col>


          </v-row>
        </v-card-text>

        <!-- CHECKBOX ... -->
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card class="mx-3" elevation="3">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-checkbox v-model="cliente_activo" label="PROVEEDOR ACTIVO" dense hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox v-model="con_retencion" label="CON RETENCION" dense hide-details
                      @change="estadoRetencion()"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox v-model="con_percepcion" label="CON PERCEPCION" dense hide-details
                      @change="estadoPercepcion()"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox v-model="exento" label="EXENTO" dense hide-details></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- EXTRANJERO, TIPO EXPORT, ZONA -->
        <v-card-text class="pb-0">
          <v-row justify="space-between" no-gutters>
            <v-col cols="auto" md="3">
              <v-checkbox v-model="exterior" label="EXTERIOR" dense hide-details></v-checkbox>
            </v-col>
            <v-col cols="12" md="5" class="pa-1">
              <v-autocomplete v-model="area" :items="items_area" item-text="text" item-value="value" label="AREA"
                outlined dense hide-details></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- EXTRANJERO, TIPO EXPORT, ZONA, PAIS -->
        <v-card-text class="pb-0">
          <v-row justify="center" no-gutters>
            <v-col cols="auto" class="pa-1" align-self="center">
              <v-btn outlined @click="verPaises()">
                <v-icon color="grey">fa-solid fa-magnifying-glass-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete v-model="id_pais" :items="items_paises" item-text="nombre" item-value="id_pais"
                label="País" outlined dense hide-details readonly></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="crearProveedor()" v-if="flag_nuevo_edit == 1">Guardar</v-btn>
          <v-btn color="primary" @click="actualizarProveedor()" v-if="flag_nuevo_edit == 2">Actualizar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogProveedor()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_paises" width="500">
      <v-data-table :headers="headers_paises" :items="items_paises" :search="search" @dblclick:row="setPais">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Países</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" label="BUSCAR" outlined dense hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">fa-solid fa-circle-info</v-icon>
              </template>
              <span>Doble click para seleccionar el país</span>
            </v-tooltip>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-dialog>

    <v-snackbar v-model="snackbar" timeout="8000" color="blue" multi-line elevation="24" outlined top right>
      <v-icon>fa-solid fa-circle-info</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import settings from "../settings/settings";

export default {
  props: ["estado_dialog", "estado_nuevo_editar_dialog", "errorDocumentoIdentificador"],
  data: () => ({
    search: "",
    id_proveedor: 0,
    codigo: "",
    nombre: "",
    id_departamento: null,
    items_departamento: [],
    id_municipio: null,
    items_municipio: [],
    direccion: "",
    tamaño: "",
    items_tamaño: ["PEQUEÑO", "MEDIANO", "GRANDE", "OTROS"],
    correo_1: "",
    correo_2: "",
    telefono: "",
    id_identificador: "",
    items_identificador: [],
    documento_identificador: "",
    registro: "",
    con_retencion: false,
    cliente_activo: false,
    exento: false,
    con_percepcion: false,
    exterior: false,
    headers_paises: [
      { text: "Codigo", value: "cod_pais", align: "center" },
      { text: "Nombre", value: "nombre" },
    ],
    id_pais: "",
    items_paises: [],
    area: "",
    items_area: [
      { text: "LOCAL", value: "L" },
      { text: "IMPORTACION", value: "I" },
      { text: "INTERNACION", value: "R" },
    ],
    id_actividad_economica: "",
    items_actividad_economica: [],
    dialog: false,
    dialog_paises: false,
    flag_nuevo_edit: 1,
    snackbar: false,
    snackbar_text: "",
  }),
  created() {
    this.listarDepartamentos();
    this.listarIdentificadores();
    this.listarTipoDocFiscales();
    this.listarTipoExportacion();
    this.listarActividadEconomica();
    this.listarPaises();
  },
  computed: {
    estadoProveedor() {
      if (this.estado_nuevo_editar_dialog > 0) {
        this.listarProveedores();
      }
    },
  },
  methods: {
    upperLetter() {
      this.codigo = this.codigo.toUpperCase();
    },
    nuevoProveedor() {
      this.flag_nuevo_edit = 1;
      this.dialog = true;
    },
    listarProveedores() {
      axios
        .get("api/Proveedores/ListarProveedor/" + this.estado_nuevo_editar_dialog)
        .then((response) => {
          this.editarProveedor(response.data[0]);
        });
    },
    async listarDepartamentos() {
      this.items_departamento = await (await settings.metodos.Departamentos()).data;
    },
    async listarMunicipios(item) {
      var departamento = await (await settings.metodos.Departamento(item)).data;

      this.items_municipio = await (await settings.metodos.Municipios(departamento.cod_depa)).data;
    },
    async listarIdentificadores() {
      this.items_identificador = await (await settings.metodos.Identificador()).data;
    },
    async listarTipoDocFiscales() {
      this.items_tipo_doc_fiscal = await (await settings.metodos.TipoDocFiscal()).data;
    },
    async listarTipoExportacion() {
      this.items_tipo_export = await (await settings.metodos.TipoExportacion()).data;
    },
    async listarActividadEconomica() {
      this.items_actividad_economica = await (await settings.metodos.Actividad_Economica()).data;
    },
    cerrarDialogProveedor() {
      this.$emit("update:estado_dialog", !this.estado_dialog);
      this.limpiarProveedor();
    },
    limpiarProveedor() {
      this.codigo = "";
      this.nombre = "";
      this.id_departamento = "";
      this.id_municipio = "";
      this.direccion = "";
      this.tamaño = "";
      this.correo_1 = "";
      this.correo_2 = "";
      this.telefono = "";
      this.id_identificador = "";
      this.documento_identificador = "";
      this.registro = "";
      this.con_retencion = false;
      this.cliente_activo = false;
      this.exento = false;
      this.con_percepcion = false;
      this.exterior = false;
      this.area = "";
      this.id_pais = "";
    },
    async listarPaises() {
      this.items_paises = await (await settings.metodos.Paises()).data;
    },
    verPaises() {
      this.dialog_paises = true;
    },
    setPais(event, { item }) {
      this.id_pais = item.id_pais;
      this.dialog_paises = false;
    },
    crearProveedor() {
      axios
        .post("api/Proveedores/Crear", {
          id_departamento: this.id_departamento,
          id_municipio: this.id_municipio,
          id_identificador: this.id_identificador,
          id_pais: this.id_pais,
          id_empresa: this.$store.state.emp.id_empresa,
          id_actividad_economica: this.id_actividad_economica,
          codigo: this.codigo,
          nombre: this.nombre,
          direccion: this.direccion,
          tamaño: this.tamaño,
          correo_1: this.correo_1,
          correo_2: this.correo_2,
          telefono: this.telefono,
          documento_identificador: this.documento_identificador,
          con_retencion: this.con_retencion,
          activo: this.cliente_activo,
          exento: this.exento,
          con_percecion: this.con_percepcion,
          exterior: this.exterior,
          registro: this.registro,
          area: this.area,
        })
        .then(() => {
          this.snackbar_text = "Registro creado.";
          this.snackbar = true;
          this.cerrarDialogProveedor();
          this.listarProveedores();
        })
        .catch(() => {
          this.snackbar_text = "No se pudo crear.";
          this.snackbar = true;
        });
    },
    editarProveedor(item) {
      this.id_proveedor = item.id_proveedor;
      this.id_empresa = item.id_empresa;
      this.id_actividad_economica = item.id_actividad_economica;
      this.cliente_activo = item.activo;
      this.codigo = item.codigo;
      this.nombre = item.nombre;
      this.id_departamento = item.id_departamento;
      this.id_municipio = item.id_municipio;
      this.direccion = item.direccion;
      this.tamaño = item.tamaño;
      this.correo_1 = item.correo_1;
      this.correo_2 = item.correo_2;
      this.telefono = item.telefono;
      this.id_identificador = item.id_identificador;
      this.documento_identificador = item.documento_identificador;
      this.registro = item.registro;
      this.con_retencion = item.con_retencion;
      this.con_percepcion = item.con_percepcion;
      this.cliente_activo = item.activo;
      this.exento = item.exento;
      this.exterior = item.exterior;
      this.area = item.area;
      this.id_pais = item.id_pais;

      this.listarMunicipios(item.id_departamento);
      this.listarActividadEconomica(item.id_actividad_economica);

      this.flag_nuevo_edit = 2;
      this.dialog = true;
    },
    actualizarProveedor() {
      axios
        .put("api/Proveedores/Actualizar", {
          id_proveedor: this.id_proveedor,
          id_departamento: this.id_departamento,
          id_municipio: this.id_municipio,
          id_identificador: this.id_identificador,
          id_pais: this.id_pais,
          id_empresa: this.$store.state.emp.id_empresa,
          id_actividad_economica: this.id_actividad_economica,
          codigo: this.codigo,
          nombre: this.nombre,
          direccion: this.direccion,
          tamaño: this.tamaño,
          correo_1: this.correo_1,
          correo_2: this.correo_2,
          telefono: this.telefono,
          documento_identificador: this.documento_identificador,
          registro: this.registro,
          con_retencion: this.con_retencion,
          activo: this.cliente_activo,
          exento: this.exento,
          con_percecion: this.con_percepcion,
          exterior: this.exterior,
          area: this.area,
        })
        .then(() => {
          this.snackbar_text = "Registro actualizado.";
          this.snackbar = true;
          this.cerrarDialogProveedor();
          this.listarProveedores();
        })
        .catch(() => {
          this.snackbar_text = "No se pudo actualizar.";
          this.snackbar = true;
        });
    },
  },
};
</script>