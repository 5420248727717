<template>
  <v-container fluid class="fill-height gradiente">
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-card width="400" outlined elevation="12">
          <v-img
            :src="require('@/assets/logo_devco.png')"
            height="100"
            contain
          ></v-img>
          <v-card-title class="justify-center"> INICIO DE SESION </v-card-title>
          <v-card-text class="pt-4">
            <v-text-field
              v-model="correo"
              label="Correo"
              outlined
              dense
              hide-details
              @keyup.enter="iniciarSesion()"
            ></v-text-field>
            <v-text-field
              v-model="contraseña"
              label="Contraseña"
              class="mt-3"
              :append-icon="
                ver_contraseña ? 'fa-solif fa-eye' : 'fa-solid fa-eye-slash'
              "
              @click:append="ver_contraseña = !ver_contraseña"
              :type="ver_contraseña ? 'text' : 'password'"
              outlined
              dense
              hide-details
              @keyup.enter="iniciarSesion()"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="iniciarSesion()">Acceder</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      timeout="5000"
      top
      right
      outlined
      color="primary"
    >
      <v-icon color="yellow">fa-solid fa-circle-info</v-icon>
      {{ snackbar_text }}
    </v-snackbar>

    <v-overlay v-model="overlay">
      <v-progress-circular
        indeterminate
        size="100"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    snackbar: false,
    snackbar_text: "Texto",
    overlay: false,
    ver_contraseña: false,
    correo: "",
    contraseña: "",
  }),
  methods: {
    iniciarSesion() {
      if (!this.correo && !this.contraseña) {
        this.snackbar_text = "Complete los campos";
        this.snackbar = true;
        return;
      }
      this.overlay = true;

      axios
        .post("api/Usuarios/Login", {
          correo: this.correo,
          password: this.contraseña,
        })
        .then((response) => {
          this.overlay = false;
          this.$store.dispatch("guardarToken", response.data.token);
        })
        .catch(() => {
          this.overlay = false;
          this.snackbar_text = "No se pudo iniciar sesión";
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
.gradiente {
  background-image: linear-gradient(
    135deg,
    #85e2fc 0,
    #5fd3f8 25%,
    #1dc3f3 50%,
    #00b3ef 75%,
    #00a4ec 100%
  );
}
</style>