<template>
  <v-container fluid>
    <v-data-table :items="items_clientes" :headers="headers_clientes">
      <template v-slot:top>
        <v-btn color="primary" @click="generarExcel()">Generar</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
var XLSX = require("xlsx");

export default {
  data: () => ({
    items_clientes: [],
    headers_clientes: [
      { text: "Código", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Teléfono", value: "telefono" },
    ],
    items_reporte: []
  }),
  created() {
    this.listar();
  },
  methods: {
    listar() {
      axios
        .get("api/Clientes/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_clientes = response.data;
        });
    },
    generarExcel() {
        this.items_clientes.forEach((element) => {
            this.items_reporte.push({
                "Fecha Ingreso": element.fecha_ingreso,
                "Código": element.codigo,
                "Nombre": element.nombre,
                "Razón social": element.razon_social,
                "Dirección": element.direccion,
                "Departamento": element.nom_departamento,
                "Municipio": element.nom_municipio,
                "Tamaño": element.tamaño,
                "Registro": element.registro,
                "Correo 1": element.correo_1,
                "Correo 2": element.correo_2,
                "Contacto": element.contacto,
                "Teléfono": element.telefono,
                "Tipo Persona": element.tipo_persona,
                "Documento": element.documento,
                "Con retención": this.convertirBooleano(element.con_retencion),
                "Activo": this.convertirBooleano(element.cliente_activo),
                "Exento": this.convertirBooleano(element.exento),
                "Con Percepcion": this.convertirBooleano(element.con_percepcion),
                "Extranjero": this.convertirBooleano(element.extranjero),
                "Zona Franca DPA": element.zona_franca_dpa,
            })
        })
      let data = XLSX.utils.json_to_sheet(this.items_reporte);
      const workbook = XLSX.utils.book_new();
      const filename = "Clientes";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    convertirBooleano(item) {
      if (item) {
        return "SI";
      } else {
        return "NO";
      }
    },
  },
};
</script>