<template>
  <v-container fluid>
    <v-data-table
      :headers="headers_permisos"
      :items="items_permisos"
      dense
      hide-default-footer
      disable-pagination
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Permisos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model="id_usuario"
            :items="items_usuarios"
            item-text="nombre"
            item-value="id_usuario"
            label="Usuario"
            outlined
            dense
            hide-details
            @change="listarPermisos()"
          ></v-autocomplete>
          <v-btn
            class="ml-1"
            color="primary"
            @click="adicionarPantallas()"
            v-if="items_permisos.length > 0"
            >Adicionar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="listarPantallas()"> Nuevo </v-btn>
          <v-menu
            v-model="menu_opciones"
            origin="center center"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>fa-solid fa-ellipsis-vertical</v-icon>
              </v-btn>
            </template>
            <v-card width="300">
              <v-card-title class="pb-0">Opciones</v-card-title>
              <v-divider></v-divider>
              <v-list>
                <v-list-item-group>
                  <v-list-item>
                    <v-list-item-title @click="nuevaPantalla()"
                      >Crear pantalla</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action_remove`]="{ item }">
        <v-checkbox
          v-model="item.visible"
          readonly
          hide-details
          dense
          @click="editarPermiso(item)"
        >
        </v-checkbox>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" scrollable persistent>
      <v-card>
        <v-card-title>Pantallas</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group>
              <v-list-item v-for="(item, i) in items_pantallas" :key="i">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="item.visible"
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.nombre_identificador
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearPermisos()"
            :disabled="this.items_pantallas.length == 0"
            >Guardar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_editar" width="500">
      <v-card>
        <v-card-title>{{ titulo_editar }}</v-card-title>
        <v-card-actions>
          <v-btn color="primary" @click="actualizarPermiso()">Actualizar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_editar = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_nueva_pantalla" width="500" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="nombre_pantalla"
                label="Nombre"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="nombre_pantalla_identificador"
                label="Nombre identificador"
                outlined
                dense
                hint="Debe coincidir el nombre en el archivo router 'path'"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="crearPantalla()">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogNuevaPantalla()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="5000"
      top
      right
      outlined
      color="primary"
    >
      <v-icon color="yellow">fa-solid fa-circle-info</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    menu_opciones: false,
    headers_permisos: [
      { text: "Nombre pantalla", value: "nombre_pantalla" },
      { text: "Editar permiso", value: "action_remove" },
    ],
    items_permisos: [],
    headers_pantallas: [{ text: "Nombre", value: "nombre" }],
    items_pantallas: [],
    dialog: false,
    dialog_editar: false,
    dialog_nueva_pantalla: false,
    id_usuario: 0,
    id_permisos_pantallas: 0,
    visible: false,
    items_usuarios: [],
    pantallas_seleccionadas: [],
    titulo_editar: "",
    snackbar: false,
    snackbar_text: "",
    nombre_pantalla: "",
    nombre_pantalla_identificador: "",
  }),
  computed: {
    //
  },
  created() {
    this.listarUsuarios();
  },
  methods: {
    listarUsuarios() {
      axios.get("api/Usuarios/Listar").then((response) => {
        this.items_usuarios = response.data;
      });
    },
    listarPantallas() {
      if (this.id_usuario <= 0) {
        this.snackbar_text = "Debe de seleccionar un usuario.";
        this.snackbar = true;
        return;
      }
      if (this.items_permisos.length > 0) {
        this.snackbar_text = "Este usuario ya posee los permisos creados.";
        this.snackbar = true;
        return;
      }
      axios.get("api/Pantallas/Listar").then((response) => {
        response.data.forEach((element) => {
          this.items_pantallas.push({
            id_pantallas: element.id_pantallas,
            nombre: element.nombre,
            nombre_identificador: element.nombre_identificador,
            visible: false,
          });
        });

        this.dialog = true;
      });
    },
    listarPermisos() {
      axios
        .get("api/PermisosPantallas/Listar/" + this.id_usuario)
        .then((response) => {
          this.items_permisos = response.data;
        });
    },
    crearPermisos() {
      for (var i = 0; i < this.items_pantallas.length; i++) {
        axios.post("api/PermisosPantallas/Crear", {
          id_pantallas: this.items_pantallas[i].id_pantallas,
          id_usuario: this.id_usuario,
          visible: this.items_pantallas[i].visible,
        });
      }
      this.cerrarDialog();
      this.listarPermisos();
    },
    editarPermiso(item) {
      this.titulo_editar = item.visible ? "Remover permiso" : "Agregar permiso";
      this.id_permisos_pantallas = item.id_permisos_pantallas;
      this.id_usuario = item.id_usuario;
      this.visible = item.visible ? false : true;

      this.dialog_editar = true;
    },
    actualizarPermiso() {
      axios
        .put("api/PermisosPantallas/Actualizar", {
          id_permisos_pantallas: this.id_permisos_pantallas,
          visible: this.visible,
        })
        .then(() => {
          this.cerrarDialogEditar();
          this.listarPermisos();
        });
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    cerrarDialogEditar() {
      this.dialog_editar = false;
    },
    cerrarDialogNuevaPantalla() {
      this.dialog_nueva_pantalla = false;

      this.limpiarNuevaPantalla();
    },
    limpiar() {
      this.items_pantallas = [];
    },
    limpiarNuevaPantalla() {
      this.nombre_pantalla = "";
      this.nombre_pantalla_identificador = "";
    },
    adicionarPantallas() {
      if (this.id_usuario <= 0) {
        this.snackbar_text = "Debe de seleccionar un usuario.";
        this.snackbar = true;
        return;
      }

      axios.get("api/Pantallas/Listar").then((response) => {
        response.data.forEach((element) => {
          var index = this.items_permisos.findIndex(
            (i) => i.id_pantallas == element.id_pantallas
          );
          if (index == -1) {
            this.items_pantallas.push({
              id_pantallas: element.id_pantallas,
              nombre: element.nombre,
              nombre_identificador: element.nombre_identificador,
              visible: false,
            });
          }
        });

        this.dialog = true;
      });
    },
    nuevaPantalla() {
      this.dialog_nueva_pantalla = true;
    },
    crearPantalla() {
      axios.post("api/Pantallas/Crear", {
        nombre: this.nombre_pantalla,
        nombre_identificador: this.nombre_pantalla_identificador
      }).then(() => {
        this.snackbar_text = "Pantalla creada.";
        this.snackbar = true;

        this.cerrarDialogNuevaPantalla();
      })
    },
  },
};
</script>