import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
    emp: {}
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
    setEmpresa(state, empresa) {
      state.emp = empresa;
    }
  },
  actions: {
    guardarToken({ commit }, token) {
      localStorage.setItem("token", token);
      commit("setToken", token);
      commit("setUsuario", decode(token));

      router.push({ name: "bienvenido" }).catch(() => {});
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token");
      if(token) {
        commit("setToken", token);
        commit("setUsuario", decode(token));

        //router.push({ name: "home"}).catch(() => {});
      } else {
        router.push({ name: "login" }).catch(() => {});
      }
    },
    guardarEmpresa({ commit }, emp) {
      commit("setEmpresa", emp);
    },
    salir({ commit }) {
      commit("setToken", null);
      commit("setUsuario", null);
      commit("setEmpresa", null);
      localStorage.removeItem("token");
      router.push({ name: "login" }).catch(() => {});
    }
  },
})
